<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Languages" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                  @input="delete errors.label"
                />
                <va-input
                  label="Code *"
                  v-model="code"
                  :messages="['The recommended number of characters is 5']"
                  :error="!!errors.code"
                  :error-messages="errors.code"
                  @input="delete errors.code"
                />
                <va-input
                  label="Locale *"
                  v-model="loc"
                  :messages="['The recommended number of characters is 5']"
                  :error="!!errors.locale"
                  :error-messages="errors.locale"
                  @input="delete errors.locale"
                />
                <va-toggle
                  small
                  label="Published"
                  v-model="published"
                ></va-toggle>
                <va-input
                  label="Position"
                  v-model.number="position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                  @input="delete errors.position"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      label: '',
      code: '',
      loc: '',
      published: false,
      position: 0,

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      const data = {
        label: this.label,
        code: this.code,
        locale: this.loc,
        published: this.published ? 1 : 0,
        position: this.position,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-language/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'shkil-settings-languages' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-language?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'shkil-settings-languages' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-language/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.position = response.data.position
            this.published = !!response.data.published
            this.code = response.data.code
            this.loc = response.data.locale
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
